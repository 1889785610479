import { useContext } from 'react';
import ResultsContext from '../../../context/ResultsContext';
import { roundWholeNumber } from '../../../helpers/rounding';
import DischargeableEnergy from '../../Charts/DischargeableEnergy';
import '../Results.scss';

export default function ResultsServiceLife() {
  const { resultsContent } = useContext(ResultsContext);
  if (resultsContent.augmentation_summary && resultsContent.dischargable_ac_energy_graph) {
    return (
      <div className="ResultsServiceLife card-basic" >
        <div style={{display:"flex", justifyContent:"space-between"}} >
            <div className="card-title-wrapper" style={{width: "50%"}}>
                <h3><span className="text-blue">Tera<span className="text-red">Stor</span>™</span> BESS Over Its Service Life</h3>
                <p className="text-light">The following are specifications for parameters that change over its service life.</p>
            </div>
            <div className="card-title-wrapper" style={{width: "50%", textAlign:"right"}}>
                <h3><span className="text-red">Augmentation Plan <span className="text-blue" style={{fontSize:25}}>{(resultsContent.augmentation_summary[resultsContent.augmentation_summary.length - 1].total_ts.toFixed(0)) - parseFloat(resultsContent.augmentation_summary[0].total_ts.toFixed(0))}</span> <span className="text-blue"> TeraStors</span> est.</span></h3>
            </div>
        </div>
        <div className="card-body-wrapper flex-wrapper">
          {!resultsContent.augmentation ? null : <div className="card-inset">
            <h3><span className="text-blue">Tera<span className="text-red">Stor</span>™</span> Augmentation Schedule</h3>

            <div className="result-card-small result-card-title">
              <span>Years</span>
              <span>Augment</span>
              <span>TeraStors</span>
            </div>
            {resultsContent.augmentation_summary.map((item, i) => (
              <div key={item.total_ts} className="result-card-small card-basic">
                <span>{item.years_past_installation}</span>
                <span>{i === 0 ? "" : roundWholeNumber(item.installed_ts)}</span>
                <span>{roundWholeNumber(item.total_ts, 0)}</span>
              </div>
            ))}
          </div>}
          <div className={`graph-card card-inset ${!resultsContent.augmentation ? "graph-card-large" : ""}`}>
            <DischargeableEnergy />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div>no results yet</div>
    )
  }

}