// cycles_per_year
export function calcCyclesPerYear(value) {
  if (value === 0) {
    value = "";
  } else if (value > 730) {
    value = Math.floor(value / 10);
  }
  return value;
}

// service_life
export function calcServiceLife(value) {
  if (value > 21) {
    value = Math.floor(value / 10);
  }
  return value;
}


// check if number or a decimal in appropriate field
export function checkNumDecimalConditions(name, value) {
  const whitelistDecimals = [
      "rated_system_power_mw",
      "discharge_duration",
      "desired_discharge_energy_mwh",
      "power_factor_support_at_poi",
      "poi_voltage"
    ];
  const reg = new RegExp('^[0-9]|^$');
  const lastChar = value.charAt(value.length - 1)

  // Check if the value has more than one decimal
  if ((value.match(/\./g) || []).length > 1) {
      return false;
  }

  // Check if value is a dot and name is included in the allowedNames array
  if (lastChar === '.' && whitelistDecimals.includes(name)) {
      return true;
  }
  // Check if value is a number
  if (reg.test(+lastChar)) {
      return true;
  }
  // If none of the conditions are met, return false
  return false;
}