let default_date = new Date();
default_date.setDate(new Date().getDate() + 28 * 7)

const FormContentBasic = {
  rated_system_power_mw: 100,
  power_factor_support_at_poi: 0.95,
  bol_eol_flag: "EOL",
  desired_discharge_energy_mwh: 400,
  cycles_per_year: 365,
  service_life: 20,
  inverter: "AESI",
  discharge_duration: 4,
  augmentation: true,
  initial_augmentation_period: 1,
  augmentation_period: 3,
  delivery_date: default_date.toISOString().slice(0, 10),
  poi_voltage: 34.5,
}

export default FormContentBasic;