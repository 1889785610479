import FormContentBasic from '../../../context/FormContentBasic';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import './FormInput.scss'

export default function FormInput({
  value,
  type = "text",
  name,
  required = false,
  handleChange,
  label,
  tooltip,
  isPercent,
  options,
  disabled,
  hidden,
  metric,
  placeholder,
  stacked,
  handleBlur
}) {
  const forbiddenSymbols = ["e", "E", "+", "-", "."];
  let labelClassName;
  required ? labelClassName = "SubmissionForm__label label-required" : labelClassName = "SubmissionForm__label"

  if (value === null) value = undefined;
  // this removes the 0 at beginning of the input number
  if (type === "number") {
    if (value !== 0 && value !== undefined) {
      value = value.toString()
    }
  }
  return (
    !hidden && (
      <div className={`SubmissionForm__section-input ${stacked ? "stacked" : ""}`}>
        <span className="input-label">
          <label className={labelClassName} htmlFor={name}>{label}</label>
          {tooltip ? (
            <Tippy content={tooltip}>
              <span className="info">
                &nbsp;&#9432;
              </span>
            </Tippy>
          ) : null}
        </span>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {type === "select" ?
            <span className="input-wrapper">
              <select
                onChange={(e) => handleChange(e)}
                className="unedited"
                value={value}
                name={name}
                id={name}
                required={required}
                disabled={disabled}
              >
                {options.map((o) => <option key={o.value} value={o.value}>{o.label}</option>)}
              </select>
            </span>
            :
            type === "date" ?
              <>
                <span className="input-wrapper">
                  <input
                    onChange={(e) => handleChange(e)}
                    onPaste={() => {return false;}}
                    className="unedited"
                    value={value}
                    type={type}
                    name={name}
                    id={name}
                    required={required}
                    placeholder={placeholder}
                    min={FormContentBasic.delivery_date}
                  />
                </span>
                {metric ? <span className="input-metric">{metric}</span> : ""}
              </>
              :
              <>
                <span className="input-wrapper">
                  <input
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    onPaste={() => {return false;}}
                    className="unedited"
                    value={value}
                    type={type}
                    name={name}
                    id={name}
                    required={required}
                    placeholder={placeholder}
                  />
                </span>
                {metric ? <span className="input-metric">{metric}</span> : ""}
              </>
          }
          {isPercent && stacked ? "%" : ""}
        </span>
        {isPercent && !stacked ? "%" : ""}
      </div>
    )
  )
}