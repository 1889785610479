import { useRef } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import ToggleSwitch from '../../ToggleSwitch/ToggleSwitch';

export default function FormToggleSwitch({
  value,
  type,
  name,
  required = false,
  handleChange,
  label,
  tooltip,
  isPercent,
  options,
  hidden,
}) {

  let labelClassName;
  required ? labelClassName = "SubmissionForm__label label-required" : labelClassName = "SubmissionForm__label"

  function onToggleSwitch(val) {
    const fakeEvent = { value: val, name, type }
    handleChange(fakeEvent)
  }

  return (
    <div className="SubmissionForm__section-input" style={{display: hidden ? 'none': true}}>
      <span className="input-label">
        <label className={labelClassName} htmlFor={name}>{label}</label>
        {tooltip ? (
          <Tippy content={tooltip}>
            <span className="info">
              &nbsp;&#9432;
            </span>
          </Tippy>
        ) : null}
      </span>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <ToggleSwitch
          name={name}
          value={value}
          callback={onToggleSwitch}
          controlRef={useRef()}
          segments={[
            {
              ...options[0],
              ref: useRef()
            },
            {
              ...options[1],
              ref: useRef()
            },
          ]}
        />
      </span>
      {isPercent ? "%" : ""}
    </div>
  )
}