const labels = {
    battery_controls_power_kw: "Battery Controls Power (KWh)",
    inverter_efficiency: "Inverter Efficiency (1-way)",
    inverter_efficiency__ONE_CP: "100%",
    inverter_efficiency__HALF_CP: "75%",
    inverter_efficiency__THIRD_CP: "50%",
    inverter_efficiency__QUARTER_CP: "25%",
    inverter_efficiency__ONE_FIFTH_CP: "10%",
    inverter_efficiency__ZERO_CP: "0%",
    inverter_idle_losses_kw: "Inverter Idle Losses (KW)",
    mv_transformer_efficiency: "MV Transformer Efficiency (1-way)",
    mv_transformer_efficiency__ONE_CP: "100%",
    mv_transformer_efficiency__HALF_CP: "75%",
    mv_transformer_efficiency__THIRD_CP: "50%",
    mv_transformer_efficiency__QUARTER_CP: "25%",
    mv_transformer_efficiency__ONE_FIFTH_CP: "10%",
    mv_transformer_efficiency__ZERO_CP: "0%",
    mv_transformer_idle_losses_kw: "MV Transformer Idle Losses (KW)",
    mv_transformer_reactance: "MV Transformer Impedance (%)",
    hv_transformer_efficiency: "HV Transformer Efficiency (1-way)",
    hv_transformer_efficiency__ONE_CP: "100%",
    hv_transformer_efficiency__HALF_CP: "75%",
    hv_transformer_efficiency__THIRD_CP: "50%",
    hv_transformer_efficiency__QUARTER_CP: "25%",
    hv_transformer_efficiency__ONE_FIFTH_CP: "10%",
    hv_transformer_efficiency__ZERO_CP: "0%",
    hv_transformer_reactance: "HV Transformer Reactance (%)",
    hv_transformer_idle_losses_kw: "HV Transformer Idle Losses (kW)",
    wiring_losses: "Wiring Losses 1-Way (%)",
    wiring_losses__ONE_CP: "100%",
    wiring_losses__HALF_CP: "75%",
    wiring_losses__THIRD_CP: "50%",
    wiring_losses__QUARTER_CP: "25%",
    wiring_losses__ONE_FIFTH_CP: "10%",
    wiring_losses__ZERO_CP: "0%",
    hvac_cop: "hvac_cop",
    hvac_fouling_factor_per_year: "hvac_fouling_factor_per_year",
    heat_gain_from_environment: "heat_gain_from_environment",
    cell_type: "EVE50SOC",
    power_rating_of_each_pcs_mva: "Power Rating of Each PCS",
    max_number_of_pcs_per_enclosure: "max_number_of_pcs_per_enclosure",
}

export default labels;