import { useContext } from 'react';
import ResultsContext from '../../context/ResultsContext';
import {roundNumber} from "../../helpers/rounding";
import SubmissionFormContext from "../../context/SubmissionFormContext";

export default function ModalContent({ parent, child }) {
  const { resultsContent } = useContext(ResultsContext);
    const { formContent } = useContext(SubmissionFormContext);
  if (parent === "terastor_ac_terminals") {
    if (child === "real_power" || child === "apparent_power") {
      return (
        <>
          <strong>Required Real Power MW and Apparent Power MVA </strong>
          at the AC terminal are derived from required Real and Apparent
          power at POI by compensating for losses in the wiring, switchgear,
          and transformers that connect the BESS to the POI. <br />
          {resultsContent.terastor_ac_terminals.apparent_power.required > resultsContent.terastor_ac_terminals.apparent_power.delivered ?
            <>
              The Terastor includes an integrated PCS that can be scaled up to 3.6MW/MVA of power maximum. We usually optimize the
              system to meet real power and energy capacity requirements. <br />
              The gap between required and delivered apparent power can be met by oversizing the system, i.e.
              adding more Terastors, but usually, a static Var compensator is a more cost-effective solution to bridge this gap.
              If this information is confusing, please feel free to reach out to AESI representative and we will be happy to investigate your
              project requriemetns and offer the best and most cost-effective solution.</>
            : null
          }
        </>
      )
    } else if (child === "usable_energy") {
      return (
        <>
          <strong>Required Usable Energy MWh </strong> at the AC terminals
          is derived from required Usable Energy at POI by compensating for losses
          in the wiring, switchgear, and transformers that connect the BESS to the POI.
          <br />
          <br />
          The delivered usable energy may be oversized because:
          <br />
          <br />
          The TeraStor comes at a fixed capacity of 7.2 MWh (at CP/4) and the total system
          capacity can only be a multiple of that number.
          <br />
          <br />
          and/or
          <br />
          <br />
          It ensures the system capacity does not degrade below the target
          usable energy {resultsContent.desired_discharge_energy_mwh} MWh before the first augmentation or end of life (EOL).
        </>
      )
    } else if (child === "round_trip_efficiency") {
      return (
        <>
          <strong>Round Trip Efficiency at the AC terminal </strong> includes the auxiliary power
          load of the TeraStor (including internal wiring losses, PCS, HVAC, BMS, and EMS).
        </>
      )
    }
  } else if (parent === "point_of_interconnect") {
    if (child === "apparent_power") {
      return (
        <>
          In order to meet the Power Factor (PF) {formContent.power_factor_support_at_poi} requirement at {roundNumber(resultsContent.point_of_interconnect.real_power.required)} MW, the system
          is <strong>required to deliver </strong> {roundNumber(resultsContent.point_of_interconnect.apparent_power.delivered)} MVA <strong>Apparent Power </strong>at the POI
          (Real Power/PF = Apparent Power).
          {resultsContent.point_of_interconnect.apparent_power.required > resultsContent.point_of_interconnect.apparent_power.delivered ?
            <>
              The Terastor includes an integrated PCS that can be scaled up to 3.6MW/MVA
              of power maximum. We usually optimize the system to meet real power and energy capacity requirements.
              <br />
              <br />
              The gap between required and delivered apparent power can be met by oversizing the system,
              i.e. adding more Terastors, but usually, a static Var compensator is a more cost-effective
              solution to bridge this gap. If this information is confusing, please feel free to reach
              out to AESI representative and we will be happy to investigate your project requriemetns
              and offer the best and most cost-effective solution.</>
            : null
          }
        </>
      )
    } else if (child === "usable_energy") {
      return (
        <>
          The delivered usable energy is oversized because:
          <br />
          <br />
          The TeraStor comes at a fixed capacity of ~ 7.2
          MWh (at CP/4) and the total system capacity can only
          be a multiple of that number
          <br />
          <br />
          and/or
          <br />
          <br />
          It ensures the system capacity does not degrade
          below the target {resultsContent.desired_discharge_energy_mwh} MWh before the first augmentation or end of life (EOL)
        </>
      )
    } else if (child === "round_trip_efficiency") {
      return (
        <>
          <strong>Round Trip Efficiency at the POI </strong>includes the auxiliary power
          load of the TeraStor (including internal wiring losses, PCS,
          HVAC, BMS, EMS) and losses in the wiring, switchgear, and transformers
          that connect the BESS to the POI (using American Energy Storage Innovations’
          assumptions based on the common BESS industry practices).
        </>
      )
    }
  }
  else {
    return <strong>no data to display</strong>
  }
}