const Battery2Defaults = [
  {
    system_capacity: 100.1,
    impedance: 100,
    calendar_loss: 0
  },
  {
    system_capacity: 99,
    impedance: 101,
    calendar_loss: 1
  },
  {
    system_capacity: 98,
    impedance: 102,
    calendar_loss: 2
  },
  {
    system_capacity: 97,
    impedance: 103,
    calendar_loss: 3
  },
  {
    system_capacity: 96,
    impedance: 104,
    calendar_loss: 4
  },
  {
    system_capacity: 95,
    impedance: 105,
    calendar_loss: 5
  },
  {
    system_capacity: 94,
    impedance: 106,
    calendar_loss: 6
  },
  {
    system_capacity: 93,
    impedance: 107,
    calendar_loss: 7
  },
  {
    system_capacity: 92,
    impedance: 108,
    calendar_loss: 8
  },
  {
    system_capacity: 91,
    impedance: 109,
    calendar_loss: 9
  },
  {
    system_capacity: 90,
    impedance: 110,
    calendar_loss: 10
  },
  {
    system_capacity: 89,
    impedance: 111,
    calendar_loss: 11
  },
  {
    system_capacity: 88,
    impedance: 112,
    calendar_loss: 12
  },
  {
    system_capacity: 87,
    impedance: 113,
    calendar_loss: 13
  },
  {
    system_capacity: 86,
    impedance: 114,
    calendar_loss: 14
  },
  {
    system_capacity: 85,
    impedance: 115,
    calendar_loss: 15
  },
  {
    system_capacity: 84,
    impedance: 116,
    calendar_loss: 16
  },
  {
    system_capacity: 83,
    impedance: 117,
    calendar_loss: 17
  },
  {
    system_capacity: 82,
    impedance: 118,
    calendar_loss: 18
  },
  {
    system_capacity: 81,
    impedance: 119,
    calendar_loss: 19
  },
  {
    system_capacity: 80,
    impedance: 120,
    calendar_loss: 20
  },
  {
    system_capacity: 79,
    impedance: 121,
    calendar_loss: 21
  },
  {
    system_capacity: 78,
    impedance: 122,
    calendar_loss: 22
  },
  {
    system_capacity: 77,
    impedance: 123,
    calendar_loss: 23
  },
  {
    system_capacity: 76,
    impedance: 124,
    calendar_loss: 24
  },
  {
    system_capacity: 75,
    impedance: 125,
    calendar_loss: 25
  },
]

export default Battery2Defaults;