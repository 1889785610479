import './Loader.scss';

export default function Loader({ isSpinning, status = "" }) {
  if (isSpinning) {
    return (
      <div className="loader-container">
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
        <div>{status}</div>
      </div>
    )
  }
  else return;
}