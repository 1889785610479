import { useState } from 'react';
import { Outlet, ScrollRestoration } from "react-router-dom";
import NavBar from "../components/Navigation/NavBar/NavBar";
import Toast from "../components/Toast/Toast";
import SubmissionFormContext from '../context/SubmissionFormContext';
import ResultsContext from '../context/ResultsContext';
import ToastContext from '../context/ToastContext';
import OverlayContext from '../context/OverlayContext';
import FormContentBasic from '../context/FormContentBasic';
import GetQuote from '../components/GetQuote/GetQuote';

export default function Root() {
  const [formContent, setFormContent] = useState(FormContentBasic);
  const [showContactForm, setShowContactForm] = useState(false);
  const [showOverlay, setOverlayContext] = useState(false);

  function setShowOverlay(newContent) {
    setOverlayContext(newContent);
  }

  function updateFormContent(newContent) {
    setFormContent(newContent);
  }

  const [resultsContent, setResultsContent] = useState({});
  function updateResultsContent(newContent) {
    setResultsContent(newContent);
  }

  const [toastContent, setToastContent] = useState({
    showToast: false,
    toastMessage: "Generic Error",
    toastStyles: { backgroundColor: "#ac141a" }
  })
  function updateToastContent(newToastContent) {
    setToastContent(newToastContent);
  }

  return (
    <SubmissionFormContext.Provider value={{ formContent, updateFormContent }}>
      <ResultsContext.Provider value={{ resultsContent, updateResultsContent }}>
        <ToastContext.Provider value={{ toastContent, updateToastContent }}>
          <OverlayContext.Provider value={{ showOverlay, setShowOverlay }}>
            <ScrollRestoration />
            <div id="detail">
              <NavBar setShowContactForm={setShowContactForm} />
              <Toast
                showToast={toastContent.showToast}
                toastMessage={toastContent.toastMessage}
                toastStyles={toastContent.toastStyles}
              />
              <div className="page-wrap">
                <Outlet />
              </div>
              {/* <Footer /> */}
              {showContactForm ?
                <GetQuote setShowContactForm={setShowContactForm} />
                : null}
            </div>
          </OverlayContext.Provider>
        </ToastContext.Provider>
      </ResultsContext.Provider>
    </SubmissionFormContext.Provider>
  );
}