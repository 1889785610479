import { useContext } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import exporting from 'highcharts/modules/exporting'
import ResultsContext from '../../context/ResultsContext';

exporting(Highcharts)

export default function DischargeableEnergy() {
  const { resultsContent } = useContext(ResultsContext);
  const chartData = resultsContent.dischargable_ac_energy_graph;

  const sawtoothResults = [];
  // let yAxisMin = +chartData[chartData.length - 1].dischargable_ac_energy - 20;
  let yAxisMax = +chartData[0].dischargable_ac_energy + 20;

  chartData.forEach((data, i) => {
    sawtoothResults.push([data.relative_date, data.dischargable_ac_energy]);
  })

  const options = {
    exporting: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        }
      },
    },
    xAxis: {
      min: 0,
      title: {
        text: "Years of Service"
      },
      gridLineWidth: 1,
      gridLineColor: '#e6e6e6',
    },
    yAxis: {
      min: 0,
      max: yAxisMax,
      title: {
        enabled: false
      },
      gridLineWidth: 1,
      gridLineColor: '#e6e6e6',
      labels: {
        formatter: function () {
          return this.value.toFixed(1)
        }
      },
      plotLines: [{
        color: '#AC141A',
        value: resultsContent.desired_discharge_energy_mwh,
        width: 2,
        label: {
          text: 'Required Energy Capacity',
          align: 'left',
          y: +15,
          style: {
            color: '#AC141A',
            fontSize: '11px'
          }
        }
      }]
    },
    title: {
      text: "Dischargeable Energy"
    },
    series: [
      {
        data: sawtoothResults,
        color: '#191970',
        lineWidth: 2,
      },
    ],
    tooltip: {
      formatter: function () {
        // Customize the tooltip content
        return '<span style="font-weight: bold">Service Year:</span> ' + this.x.toFixed(0) + '<br/>' +
          '<span style="font-weight: bold">Dischargeable Energy MWh:</span> ' + this.y.toFixed(1);
      }
    }
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  )
}