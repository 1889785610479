const CurrentConfiguration = {
    battery_controls_power_kw: 0.000025,
    inverter_efficiency: {
        ONE_CP: 98.5,
        HALF_CP: 98.5,
        THIRD_CP: 98.5,
        QUARTER_CP: 98.5,
        ONE_FIFTH_CP: 98.5,
        ZERO_CP: 98.5
    },
    inverter_idle_losses_kw: 0.00025,
    mv_transformer_efficiency: {
        ONE_CP: 99.2,
        HALF_CP: 99.4,
        THIRD_CP: 99.6,
        QUARTER_CP: 99.8,
        ONE_FIFTH_CP: 99.9,
        ZERO_CP: 99.99
    },
    mv_transformer_idle_losses_kw: 0.0007,
    mv_transformer_reactance: 5.75,
    hv_transformer_efficiency: {
        ONE_CP: 100.0,
        HALF_CP: 100.0,
        THIRD_CP: 100.0,
        QUARTER_CP: 100.0,
        ONE_FIFTH_CP: 100.0,
        ZERO_CP: 99.99
    },
    hv_transformer_reactance: 0.0,
    hv_transformer_idle_losses_kw: 0.0,
    wiring_losses: {
        ONE_CP: 2.0,
        HALF_CP: 1.0,
        THIRD_CP: 0.75,
        QUARTER_CP: 0.5,
        ONE_FIFTH_CP: 0.4,
        ZERO_CP: 0.0
    },
    hvac_cop: 4,
    hvac_fouling_factor_per_year: 0.75,
    heat_gain_from_environment: 1,
    cell_type: "EVE50SOC",
    power_rating_of_each_pcs_mva: 1.8,
    max_number_of_pcs_per_enclosure: 4,
}

export default CurrentConfiguration;