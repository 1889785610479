export function roundNumber(num) {
  if (Math.abs(num) < 10 ) return num.toFixed(2);
  if (Math.abs(num) >= 10 && num < 100 ) return num.toFixed(1);
  if (Math.abs(num) >= 100 ) return num.toFixed(0);
}

export function roundWholeNumber(num) {
  return num.toFixed(0)
}

// Make sure we round to 2 if there's decimals, also remove end . if there's nothing after, otherwise ignore
export function formatValue(value) {
    // Check if the value ends with '.'
    if (value.endsWith('.')) {
        // Remove the '.' at the end
        value = value.slice(0, -1);
    } else if (value.includes('.')) {
        // Check if value contains a '.' and decimals
        const decimalIndex = value.indexOf('.');
        const decimals = value.slice(decimalIndex + 1);
        if (decimals.length > 2) {
            // Round to 2 decimal places if more than 2 decimals
            value = parseFloat(value).toFixed(2);
        }
    }
    return value;
}