import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ReactDOM from 'react-dom/client';
import Root from "./routes/Root";
import FinalForm from "./routes/FinalForm/FinalForm";
import AdminPanel from "./routes/AdminPanel/AdminPanel";
import PlainHtmlPage from './routes/Iframe/Iframe';
import reportWebVitals from './reportWebVitals';
import ErrorPage from "./error-page";

import './index.scss';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <FinalForm /> },
      {
        path: "/admin",
        element: <AdminPanel />,
      },
    ]
  },
  {
    path: "/iframe.html",
    element: <PlainHtmlPage />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />
);

reportWebVitals();
