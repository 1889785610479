import React, { useState } from "react";
import CurrentConfiguration from "../../../context/CurrentConfiguration";
import labels from "../../../content/labels";
import "./EditAllForm.scss";

export default function EditAllForm({ formData, setFormData, editChangedData }) {
  // TODO: this will come from API


  const createDefaults = (data, prefix = '', isNested = false) => {
    return Object.keys(data).map((key, index) => {
      const value = data[key];
      const newPrefix = prefix.length > 0 ? prefix + '__' + key : key;

      if (key === "bol_eol_flag" ||
        key === "usage_profile" ||
        key === "cell_type" ||
        key === "battery_controls_power_basis" ||
        key === "hv_transformer_idle_losses_basis" ||
        key === "mv_transformer_idle_losses_basis") return null;

      if (typeof value === 'object' && value !== null) {
        return (
          <React.Fragment key={newPrefix}>
            <tr>
              <td colSpan="2" className="nested-header">{labels[key]}</td>
            </tr>
            {createDefaults(value, newPrefix, true)}
          </React.Fragment>
        );
      } else {
        let inputType = typeof value === "boolean" ? "checkbox"
          : typeof value === "number" ? "number"
            : "text"

        if (formData[key] === null) {
          setFormData(data => ({
            ...data,
            [key]: undefined
          }))
        }
        let firstProperty;
        let secondProperty;
        if (newPrefix.includes("__")) {
          let placeholderPrefix = newPrefix.replace("__", " ").split(" ");
          firstProperty = placeholderPrefix[0];
          secondProperty = placeholderPrefix[1];
        }
        return (
          <tr key={newPrefix}>
            {isNested ?
              <td><span style={{ marginLeft: 2 + 'em' }}>{labels[newPrefix]}</span></td>
              :
              <td>{labels[key]}</td>}
            {isNested ?
              <td><input onPaste={pastInputs} onChange={(e) => editFormItem(e, prefix + " " + labels[newPrefix])} name={newPrefix} id={newPrefix} type={inputType} value={formData[firstProperty][secondProperty]} /></td>
              : inputType === "checkbox" ?
                <td><span style={{ display: 'flex', alignItems: 'center' }}><input onChange={() => setFormData(data => ({ ...data, [key]: !formData[key] }))} name={key} type={inputType} checked={formData[key]} /><label htmlFor={newPrefix}>{formData[key] ? "true" : "false"}</label></span></td>
                :
                <td><input onChange={(e) => editFormItem(e, labels[key])} name={key} type={inputType} value={formData[key]} /></td>
            }

          </tr>
        );
      }
    });
  };

  const editFormItem = (e, labelName) => {
    const { name, value, type } = e.target;

    editChangedData(labelName)

    let parsedValue = value;

    if (type === "number") {
      parsedValue = +value;
    }

    if (value === "true" || value === "false") {
      parsedValue = value === "true"
    }

    if (name.includes("__")) {
      let newName = name.replace("__", " ").split(" ");
      const firstProperty = newName[0];
      const secondProperty = newName[1];
      const propertyObj = formData[`${firstProperty}`];

      setFormData(data => ({
        ...data,
        [firstProperty]: {
          ...propertyObj,
          [secondProperty]: parsedValue
        }
      }))
    } else {
      setFormData(data => ({
        ...data,
        [name]: parsedValue
      }))
    }
  }

  const pastInputs = (e) => {
    e.preventDefault();
    try {
      // get values from the clipboard, separate, and clean
      let value = e.clipboardData.getData('Text');
      let values = value.split(/[\t,\n]+/);
      values = values.map((item) => {
        return item.replace("%", "");
      })
      // get the associated inputs
      const parentName = e.target.name.split("__")[0];
      let inputs = formData[`${parentName}`];
      // look inputs and update the values
      Object.keys(inputs).forEach(function (key, index) {
        inputs[key] = +values[index];
      });
      // set the data in the form state
      setFormData(data => ({
        ...data,
        [parentName]: inputs
      }))

    } catch {
      console.log("ERROR PASTING DATA")
    }
  }


  return (
    <>
      {createDefaults(CurrentConfiguration)}
      {/* Static cause select options */}
      <tr>
        <td>BOL or EOL Energy Capacity</td>
        <td>
          <select onChange={(e) => editFormItem(e, "bol_eol_flag")} name="bol_eol_flag" id="bol_eol_flag" value={formData["bol_eol_flag"]} >
            <option value="BOL">BOL</option>
            <option value="EOL">EOL</option>
          </select>
        </td>
      </tr>
      <tr>
        <td>Usage Profile</td>
        <td>
          <select onChange={(e) => editFormItem(e, "usage_profile")} name="usage_profile" id="usage_profile" value={formData["usage_profile"]}>
            <option value="CONSTANT_THROUGHPUT">Constant Throughput</option>
            <option value="CONSTANT_CYCLING">Constant Cycling</option>
          </select>
        </td>
      </tr>
      <tr>
        <td>Battery Controls Power Basis</td>
        <td>
          <select onChange={(e) => editFormItem(e, "battery_controls_power_basis")} name="battery_controls_power_basis" id="battery_controls_power_basis" value={formData["battery_controls_power_basis"]}>
            <option value="PER_SYSTEM">per system</option>
            <option value="PER_RATED_KW">per rated kW</option>
            <option value="PER_MAX_KW">per max kW</option>
            <option value="PER_AC_DISCHARGE_KWH">per AC Discharge kWh</option>
            <option value="PER_DC_DISCHARGE_KWH">per DC Discharge kWh</option>
            <option value="PER_COOLING_TON">per cooling ton</option>
          </select>
        </td>
      </tr>
      <tr>
        <td>HV transformer Idle Losses Basis</td>
        <td>
          <select onChange={(e) => editFormItem(e, labels["hv_transformer_idle_losses_basis"])} name="hv_transformer_idle_losses_basis" id="hv_transformer_idle_losses_basis" value={formData["hv_transformer_idle_losses_basis"]}>
            <option value="PER_SYSTEM">per system</option>
            <option value="PER_RATED_KW">per rated kW</option>
            <option value="PER_MAX_KW">per max kW</option>
            <option value="PER_AC_DISCHARGE_KWH">per AC Discharge kWh</option>
            <option value="PER_DC_DISCHARGE_KWH">per DC Discharge kWh</option>
            <option value="PER_COOLING_TON">per cooling ton</option>                      </select>
        </td>
      </tr>
      <tr>
        <td>MV transformer Idle Losses Basis</td>
        <td>
          <select onChange={(e) => editFormItem(e, "mv_transformer_idle_losses_basis")} name="mv_transformer_idle_losses_basis" id="mv_transformer_idle_losses_basis" value={formData["mv_transformer_idle_losses_basis"]}>
            <option value="PER_SYSTEM">per system</option>
            <option value="PER_RATED_KW">per rated kW</option>
            <option value="PER_MAX_KW">per max kW</option>
            <option value="PER_AC_DISCHARGE_KWH">per AC Discharge kWh</option>
            <option value="PER_DC_DISCHARGE_KWH">per DC Discharge kWh</option>
            <option value="PER_COOLING_TON">per cooling ton</option>                      </select>
        </td>
      </tr>
      <tr>
        <td>Cell Type</td>
        <td>
          <select onChange={(e) => editFormItem(e, "cell_type")} name="cell_type" id="cell_type" value={formData["cell_type"]}>
            <option value="CATL">CATL</option>
            <option value="EVE">EVE</option>
            <option value="EVE50SOC">EVE50SOC</option>
            <option value="EVE100SOC">EVE100SOC</option>
            <option value="GENERIC">GENERIC</option>                     </select>
        </td>
      </tr>
    </>
  )
}