import { useState, useContext } from 'react';
import SubmissionForm from '../../components/Forms/SubmissionForm/SubmissionForm';
import ResultsServiceLife from '../../components/Results/ResultsServiceLife/ResultsServiceLife';
import ResultsInitial from '../../components/Results/ResultsInitial/ResultsInitial';
import ResultsOverview from '../../components/Results/ResultsOverview/ResultsOverview';
import VisualizationIFrame from '../../components/Results/VisualizationIFrame/VisualizationIFrame'
import OverlayContext from '../../context/OverlayContext';
import teraStor from '../../images/AESI-TeraStor-72dpi-Doors.png';
import teraStorClosed from '../../images/terastor-closed.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import './FinalForm.scss';

export default function FinalForm() {
  const [showResults, setShowResults] = useState(false);
  const [showConfig, setShowConfig] = useState(true);
  const [closedContainer, setClosedContainer] = useState(false);
  const { showOverlay, setShowOverlay } = useContext(OverlayContext);
  return (
    <div className="FinalForm" id="FinalForm">
      {showResults && !showOverlay ?
        <div className={`show-config ${showConfig ? "open" : ""}`} onClick={() => setShowConfig(!showConfig)}>
          <span className="open-close-icon">{showConfig ? <FontAwesomeIcon icon={faXmark} /> : <FontAwesomeIcon icon={faBars} />}</span>
        </div>
        :
        null
      }

      <SubmissionForm
        showConfig={showConfig}
        setShowConfig={setShowConfig}
        showResults={showResults}
        setShowResults={setShowResults}
        setClosedContainer={setClosedContainer}
        closedContainer={closedContainer}
        showOverlay={showOverlay}
        setShowOverlay={setShowOverlay}
      />
      {/* starting page */}
      <div className={`terastar-img ${showResults ? 'hide' : ''}`}>
        {closedContainer ? <img src={teraStorClosed} alt="terastor with closed doors" /> : <img src={teraStor} alt="terastor with open doors" />}

      </div>
      {/* after results */}
      <div className={`results-wrapper ${showResults ? '' : 'hide'} ${showConfig ? "" : "larger"}`}>
        <div className="results-max-w">
          {showOverlay ?
            <div className='changeOverlay'>
              <div className='text'>
                <button className="red" onClick={
                  (e) => {
                    let submissionButton = document.getElementById("submissionButton");
                    submissionButton.click();
                  }
                }>Update Configuration</button>
              </div>
            </div>
            :
            null
          }
          <ResultsOverview />
          <ResultsInitial />
          <ResultsServiceLife />
            <VisualizationIFrame />
        </div>
      </div>
    </div >
  );
}