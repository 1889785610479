import { useContext, useEffect } from 'react';
import ToastContext from '../../context/ToastContext';
import './Toast.scss'

export default function Toast({ toastMessage = "Generic Error", showToast = false, toastStyles = { backgroundColor: "#ac141a" } }) {
  const { toastContent, updateToastContent } = useContext(ToastContext);
  function hideToast() {
    updateToastContent({
      ...toastContent,
      showToast: false
    })
  }

  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        hideToast()
      }, 5000);
    }
  }, [showToast]);

  return (
    <div className={`Toast ${!showToast ? "closed" : ""}`} style={toastStyles}>
      <div className="close" onClick={hideToast}>&#10005;</div>
      {toastMessage}
    </div>
  )
}