import React, { useState, useContext } from 'react';
import Loader from '../../components/Loader/Loader';
import BatteryForm from '../../components/Forms/AdminForm/BatteryForm';
import EditAllForm from '../../components/Forms/AdminForm/EditAllForm';
import CurrentConfiguration from '../../context/CurrentConfiguration';
import Battery1Defaults from '../../context/Battery1Defaults';
import Battery2Defaults from '../../context/Battery2Defaults';
import ToastContext from '../../context/ToastContext';
import './AdminPanel.scss';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import awsExports from '../../aws-exports';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import labels from '../../content/labels';
Amplify.configure(awsExports);

function AdminPanel({ signOut, user }) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(CurrentConfiguration);
  const [batteryData1, setBatteryData1] = useState(Battery1Defaults);
  const [batteryData2, setBatteryData2] = useState(Battery2Defaults);
  const { toastContent, updateToastContent } = useContext(ToastContext);
  const [changedData, setChangedData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  let nullValues = [];

  function showTabContent(event) {
    document.querySelectorAll('.active-tab').forEach(el => el.classList.remove('active-tab'));
    document.getElementById(`${event.target.dataset.id}`).classList.add('active-tab')
    event.target.classList.add('active-tab')
  }

  function isNullOrUndefinedOrEmpty(value) {
    return value === null || value === undefined || value === '';
  }

  async function checkFormData(obj) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        if (isNullOrUndefinedOrEmpty(value)) {
          nullValues.push(labels[key])
        } else if (typeof value === 'object') {
          // If the value is an object, recursively check it
          checkFormData(value);
        }
      }
    }
    return;
  }

  function editChangedData(key) {
    setChangedData([...changedData, key])
  }

  async function submitModal(e) {
    await checkFormData(formData);
    if (nullValues.length > 0) {
      updateToastContent({
        ...toastContent,
        toastMessage: `api not connected. ${nullValues.join(", ")} must have a value`,
        showToast: true,
        toastStyles: { backgroundColor: "#AC141A" }
      })
      nullValues = [];
    } else {
      // remove duplicates
      const allFieldsEditedSet = new Set(changedData);
      // variable for if statement, state could take longer to change
      const allEditedFields = Array.from(allFieldsEditedSet);
      // setChanged data to array without duplicates
      setChangedData(Array.from(allFieldsEditedSet))
      if (allEditedFields.length > 0) {
        setShowModal(true);
      } else {
        submit();
      }
    }
  }

  function submit() {
    setChangedData([]);
    setShowModal(false);
    updateToastContent({
      ...toastContent,
      toastMessage: "api not connected, edit's would be saved at this point",
      showToast: true,
      toastStyles: { backgroundColor: "#003875" }
    })
  }

  if (loading) {
    return <Loader isSpinning={loading} />
  } else {
    return (
      <div className="AdminPanel">
        <Rodal className="modal-wrapper" visible={showModal} onClose={() => setShowModal(false)} customStyles={{ height: 'auto', maxHeight: "400px" }}>
          <br />
          Are you sure that you want to edit the following fields?
          <br />
          <br />
          <ul
            style={{
              height: 'auto',
              maxHeight: '200px',
              overflow: 'auto',
              boxShadow: 'inset 0px 0px 14px 3px rgba(0,0,0,.15)',
              padding: '.5em'
            }}>
            {changedData.map(item => <p key={item}><b>{item}</b></p>)}
          </ul>
          <br />
          <button onClick={submit}>Save</button>
          <button onClick={() => setShowModal(false)}>Cancel</button>
        </Rodal>
        <h1>Admin Panel</h1>
        <div className="AdminPanel-sections">
          <div>
            <div className="tab">
              <button onClick={showTabContent} className="tablinks active-tab" data-id="Defaults">Defaults</button>
              <button onClick={showTabContent} className="tablinks" data-id="Battery-1" >Battery 1</button>
              <button onClick={showTabContent} className="tablinks" data-id="Battery-2" >Battery 2</button>
            </div>
            <div id="Defaults" className="tabcontent active-tab card-basic">
              <h2>Defaults</h2>
              <table>
                <tbody>
                  <tr>
                    <th>Name</th>
                    <th>Value</th>
                  </tr>
                  <EditAllForm editChangedData={editChangedData} formData={formData} setFormData={setFormData} />
                </tbody>
              </table>
            </div>
            <div id="Battery-1" className="tabcontent card-basic">
              <BatteryForm batteryData={batteryData1} setBatteryData={setBatteryData1} />
            </div>
            <div id="Battery-2" className="tabcontent card-basic">
              <BatteryForm batteryData={batteryData2} setBatteryData={setBatteryData2} />
            </div>
          </div>
          <div className="AdminPanel-right">
            <div className="AdminPanel-buttons">
              <button onClick={e => submitModal(e)}>Save</button>
              <button onClick={signOut}>Sign Out</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withAuthenticator(AdminPanel);