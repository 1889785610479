import './BatteryForm.scss';

export default function BatteryForm({ batteryData, setBatteryData }) {

  function updateBatteryData(e, i, name) {
    let newValue;
    const tempDefaults = [...batteryData];
    const updatedObject = { ...tempDefaults[i] };
    if (e.target.value === "") {
      newValue = 0;
    } else {
      newValue = e.target.value
    }
    updatedObject[name] = newValue;
    tempDefaults[i] = updatedObject;

    setBatteryData(tempDefaults);
  }

  const pasteArrayData = (e) => {
    e.preventDefault();
    try {
      const tempDefaults = [...batteryData];
      let value = e.clipboardData.getData('Text');
      let values = value.split(/[\t,\n]+/);
      values = values.map((item) => {
        return item.replace("%", "");
      })
      let start = 0;
      tempDefaults.map((tempObj) => {
        tempObj.system_capacity = +values[start]
        start++
        tempObj.impedance = +values[start]
        start++
        tempObj.calendar_loss = +values[start]
        start++
        return null;
      })
      setBatteryData(tempDefaults);

    } catch {
      console.log("ERROR PASTING DATA 2")
    }
  }

  const createBattery = (data) => {
    return data.map((o, i) => {
      return (
        <tr key={i}>
          <td><input onPaste={i === 0 ? (e) => pasteArrayData(e) : null} name="system_capacity" onChange={e => updateBatteryData(e, i, "system_capacity")} type="number" value={batteryData[i].system_capacity} /></td>
          <td><input name="impedance" onChange={e => updateBatteryData(e, i, "impedance")} type="number" value={o.impedance} /></td>
          <td><input name="calendar_loss" onChange={e => updateBatteryData(e, i, "calendar_loss")} type="number" value={o.calendar_loss} /></td>
        </tr>
      )
    })
  }

  return (
    <>
      <h2>Battery</h2>
      <table>
        <thead>
          <tr>
            <th>System</th>
            <th>Impedance</th>
            <th>Losses</th>
          </tr>
        </thead>
        <tbody>
          {createBattery(batteryData)}
        </tbody>
      </table>
    </>
  )
}