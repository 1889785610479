import { useContext, useState } from 'react';
import ResultsContext from '../../../context/ResultsContext';
import { roundNumber } from '../../../helpers/rounding';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import transformerIcon from '../../../images/transformer_light.png'
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import ModalContent from '../../ModalContent/ModalContent';
import '../Results.scss';
import Tippy from "@tippyjs/react";

export default function ResultsInitial() {
  const { resultsContent } = useContext(ResultsContext);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({ parent: "", child: "" })

  function handleShowModal(parent, child) {
    setModalContent({ parent: parent, child: child })
    setShowModal(true)
  }

  if (resultsContent.terastor_ac_terminals && resultsContent.point_of_interconnect) {
    return (
      <div className="ResultsInitial card-basic">
        <Rodal className="modal-wrapper" visible={showModal} onClose={() => setShowModal(false)}>
          <ModalContent parent={modalContent.parent} child={modalContent.child} />
        </Rodal>
        <div className="card-title-wrapper">
          <h3><span className="text-blue">Tera<span className="text-red">Stor</span>™</span> BESS on Day 1</h3>
          <p className="text-light">The following are the specifications for the TeraStor site at the time of installation.</p>
        </div>

        <div className="card-body-wrapper">
          <div className="card-inset">
            <h3><span className="text-blue">Tera<span className="text-red">Stor</span>™</span> AC Terminals</h3>

            <div className="result-card-small result-card-title">
              <span></span>
              <span>Required</span>
              <span>Delivered</span>
            </div>
            <div className="result-card-small card-basic">
              <h4>Real Power MW</h4>
              <span>{roundNumber(resultsContent.terastor_ac_terminals.real_power.required)}</span>
              <span>{roundNumber(resultsContent.terastor_ac_terminals.real_power.delivered)}</span>
              <span onClick={() => handleShowModal("terastor_ac_terminals", "real_power")} className="tooltip-icon"><FontAwesomeIcon icon={faEllipsisVertical} /></span>
            </div>
            <div className="result-card-small card-basic">
              <h4>Apparent Power MVA</h4>
              <span>{roundNumber(resultsContent.terastor_ac_terminals.apparent_power.required)}</span>
              <span>{roundNumber(resultsContent.terastor_ac_terminals.apparent_power.delivered)}</span>
              <span onClick={() => handleShowModal("terastor_ac_terminals", "apparent_power")} className="tooltip-icon"><FontAwesomeIcon icon={faEllipsisVertical} /></span>
            </div>
            <div className="result-card-small card-basic">
              <h4>Usable Energy MWh</h4>
              <span>{roundNumber(resultsContent.terastor_ac_terminals.usable_energy.required)}</span>
              <span>{roundNumber(resultsContent.terastor_ac_terminals.usable_energy.delivered)}</span>
              {resultsContent.terastor_ac_terminals.usable_energy.required < (resultsContent.terastor_ac_terminals.usable_energy.delivered * .98) ?
                <span onClick={() => handleShowModal("terastor_ac_terminals", "usable_energy")} className="tooltip-icon"><FontAwesomeIcon icon={faEllipsisVertical} /></span>
                : <></>}
            </div>
            <div className="result-card-small card-basic">
              <h4>Round Trip Efficiency</h4>
              <span></span>
              <span>{roundNumber(resultsContent.terastor_ac_terminals.round_trip_efficiency) * 100}%</span>
              <span onClick={() => handleShowModal("terastor_ac_terminals", "round_trip_efficiency")} className="tooltip-icon"><FontAwesomeIcon icon={faEllipsisVertical} /></span>
            </div>
          </div>
          <div className="transformer-icon">
            <img src={transformerIcon} alt="transformer icon" title="Assumes industry-average losses and reactance for transformers/switchgear connecting to the POI." />
            <div>
              <Tippy content="Assumes industry-average losses and reactance for transformers/switchgear connecting to the POI.">
                <span className="info">
                  &nbsp;&#9432;
                </span>
              </Tippy>
            </div>
          </div>
          <div className="card-inset">
            <h3>Point of Interconnect (POI)</h3>

            <div className="result-card-small result-card-title">
              <span></span>
              <span>Required</span>
              <span>Delivered</span>
            </div>
            <div className="result-card-small card-basic">
              <h4>Real Power MW</h4>
              <span>{roundNumber(resultsContent.point_of_interconnect.real_power.required)}</span>
              <span>{roundNumber(resultsContent.point_of_interconnect.real_power.delivered)}</span>
            </div>
            <div className="result-card-small card-basic">
              <h4>Apparent Power MVA</h4>
              <span>{roundNumber(resultsContent.point_of_interconnect.apparent_power.required)}</span>
              <span>{roundNumber(resultsContent.point_of_interconnect.apparent_power.delivered)}</span>
              <span onClick={() => handleShowModal("point_of_interconnect", "apparent_power")} className="tooltip-icon"><FontAwesomeIcon icon={faEllipsisVertical} /></span>
            </div>
            <div className="result-card-small card-basic">
              <h4>Usable Energy MWh</h4>
              <span>{roundNumber(resultsContent.point_of_interconnect.usable_energy.required)}</span>
              <span>{roundNumber(resultsContent.point_of_interconnect.usable_energy.delivered)}</span>
              {resultsContent.point_of_interconnect.usable_energy.required < (resultsContent.point_of_interconnect.usable_energy.delivered * .98) ?
                <span onClick={() => handleShowModal("point_of_interconnect", "usable_energy")} className="tooltip-icon"><FontAwesomeIcon icon={faEllipsisVertical} /></span>
                : <></>}
            </div>
            <div className="result-card-small card-basic">
              <h4>Round Trip Efficiency</h4>
              <span></span>
              <span>{roundNumber(resultsContent.point_of_interconnect.round_trip_efficiency) * 100}%</span>
              <span onClick={() => handleShowModal("point_of_interconnect", "round_trip_efficiency")} className="tooltip-icon"><FontAwesomeIcon icon={faEllipsisVertical} /></span>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div>no results yet</div>
    )
  }

}